import React, { useEffect, useState } from "react";
import {
    Avatar,
    Box,
    Card,
    CardActionArea,
    CardMedia,
    Typography,
} from "@mui/material";
import { KeyboardArrowRight as KeyboardArrowRightIcon } from "@mui/icons-material";
import LocalDeliveryMethod from "./LocalDeliveryMethod";
import { getCurrency } from "../../apicalls";

const CheckoutShopCart = ({ product }) => {
    const [open, setOpen] = useState(false);

    const handleOpenModal = () => {
        setOpen(true);
    };

    const handleCloseModal = () => {
        setOpen(false);
    };
    const [useCurrency, setUseCurrency] = React.useState({});
    useEffect(() => {
        (async () => {
            const result = await getCurrency();
            setUseCurrency(result.data.item[0]);
        })();
    }, []);

    const productPrice = product.priceSell * product.rate;

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "16px 24px",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                    fullWidth
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <Avatar
                            variant="square"
                            sx={{
                                width: "60px",
                                height: "60px",
                            }}
                            src={`https://dev2.mtlexpress.com.mm/qq-delivery-backend/v1/image/download/${product?.shopLogo}`}
                        />
                        <Typography
                            variant="subtitle1"
                            sx={{
                                marginX: "10px",
                                fontWeight: "bold",
                            }}
                        >
                            {product?.shopName}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: "24px",
                    padding: "16px 24px",
                }}
            >
                {/*  */}
                <Card sx={{ width: "200px" }}>
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            image={`https://dev2.mtlexpress.com.mm/qq-delivery-backend/v1/image/download/${
                                product.images ? product.images[0] : ""
                            }`}
                            alt="Product Image"
                        />
                    </CardActionArea>
                </Card>
                {/*  */}
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                    }}
                >
                    <Typography variant="h6">
                        {/* OPPO A31, Ram 8GB+ Rom 128GB; 4G LTE; Fingerprint;
                        Camera 12MP+2MP+2MP; Front 8MP */}
                        {product.productName}
                    </Typography>
                    <Typography color="secondary">
                        {product.name}
                        {product.weight ? ` / ${product.weight} Kg` : ""}
                    </Typography>
                </Box>
                {/*  */}
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        width: "20%",
                    }}
                >
                    <Typography>Ks {productPrice.toLocaleString()}</Typography>
                    <Typography variant="body2" color={"secondary"}>
                        (Approximately{" "}
                        {/* {(product.priceSell / useCurrency.rate).toFixed(2)}  */}
                        {product.priceSell} $)
                    </Typography>
                    <Typography fontWeight={"bold"}>Quantity: {product.cartQty} </Typography>
                </Box>
            </Box>
            <CardActionArea onClick={handleOpenModal}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "16px 24px",
                        gap: "16px",
                        alignSelf: "stretch",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                        }}
                    >
                        <Typography>Urgent: 10,000 Ks</Typography>
                        <Typography>Estimated time: 30 mins</Typography>
                    </Box>
                    <Box>
                        <KeyboardArrowRightIcon />
                    </Box>
                </Box>
            </CardActionArea>
            <LocalDeliveryMethod
                product={product}
                open={open}
                handleCloseModal={handleCloseModal}
            />
        </>
    );
};

export default CheckoutShopCart;
