import React, { useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "../../product.css";
import { useAuth } from "../../DetailProvider";

const ProductPhoto = ({ variantDetailImg }) => {
    const [selectedImageGuid, setSelectedImageGuid] = useState(null);
    const {
        detailInfo,
        setDetailInfo,
        loadingContext,
        setLoadingContext,
        selectImgae,
    } = useAuth();

    // useEffect(() => {
    //     if (selectedImageGuid === null && proDetails.length > 0) {
    //         // Set the guid of the first image as the initial value for detailInfo
    //         setDetailInfo(proDetails[0].guid);
    //     }

    //     if (detailInfo) {
    //         setLoadingContext(false);
    //     } else {
    //         setLoadingContext(true);
    //     }
    // }, [
    //     selectedImageGuid,
    //     proDetails,
    //     detailInfo,
    //     setDetailInfo,
    //     setLoadingContext,
    // ]);

    const renderArrowNext = (onClickHandler, hasNext, label) => {
        if (hasNext) {
            return (
                <button
                    type="button"
                    onClick={onClickHandler}
                    title={label}
                    className="carousel-arrow next"
                >
                    Next
                </button>
            );
        }
        return null;
    };

    const renderArrowPrev = (onClickHandler, hasPrev, label) => {
        if (hasPrev) {
            return (
                <button
                    type="button"
                    onClick={onClickHandler}
                    title={label}
                    className="carousel-arrow prev"
                >
                    ok
                </button>
            );
        }
        return null;
    };

    const handleImageClick = guid => {
        setSelectedImageGuid(guid);
        setDetailInfo(guid);
    };

    return (
        <div>
            <Carousel
                autoFocus
                infiniteLoop
                renderArrowNext={renderArrowNext}
                renderArrowPrev={renderArrowPrev}
                showArrows={false}
                showStatus={false}
                showThumbs={true}
                showIndicators={false}
                onClickThumb={(index, item) =>
                    handleImageClick(variantDetailImg[index].guid)
                }
            >
                {variantDetailImg.map(vdi => {
                    return (
                        <div key={vdi.guid}>
                            {selectImgae ? (
                                <img
                                    src={`https://dev2.mtlexpress.com.mm/qq-delivery-backend/v1/image/download/${selectImgae}`}
                                    alt="Slide 1"
                                />
                            ) : (
                                <img
                                    src={`https://dev2.mtlexpress.com.mm/qq-delivery-backend/v1/image/download/${vdi.image}`}
                                    alt="Slide 1"
                                />
                            )}
                        </div>
                    );
                })}
            </Carousel>
        </div>
    );
};

export default ProductPhoto;
