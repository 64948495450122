import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { Avatar, Typography } from "@mui/material";
import { getAddOn } from "../../apicalls";
import { useParams } from "react-router-dom";

export default function AddOn({pguid}) {
    const [checked, setChecked] = React.useState([]); // Initialize with an empty array
    const [addOns, setAddOns] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        (async () => {
            const result = await getAddOn(pguid);
            setAddOns(result.data.items);
            setLoading(false);
            // console.log(result);
            // console.log(pguid);
        })();
    }, [pguid]);

    const handleToggle = value => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    return (
        !loading && (
            <List sx={{ width: "100%" }}>
            {addOns.map((item, index) => {
                const value = item.guid; // Assuming you have an 'id' property in the 'item' object
                const labelId = `checkbox-list-label-${value}`;

                return (
                    <ListItem
                        key={value}
                        secondaryAction={
                            <Typography variant="h5">
                                Ks: {item.value}
                            </Typography>
                        }
                        disablePadding
                    >
                        <ListItemButton
                            role={undefined}
                            onClick={handleToggle(value)}
                            dense
                        >
                            <Checkbox
                                edge="start"
                                checked={checked.indexOf(value) !== -1}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ "aria-labelledby": labelId }}
                            />
                            <Avatar
                                alt="Remy Sharp"
                                variant="square"
                                sx={{ width: 50, height: 50 }}
                                src={item.imageUrl} // Assuming you have an 'imageUrl' property in the 'item' object
                            />
                            <ListItemText
                                sx={{ pl: 2, fontSize: "20px" }}
                                id={labelId}
                                primary={item.name} // Assuming you have a 'name' property in the 'item' object
                            />
                        </ListItemButton>
                    </ListItem>
                );
            })}
        </List>
        )
    );
}
