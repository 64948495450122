import {
    Avatar,
    Box,
    Button,
    Card,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
    getAllStates,
    getAllTownships,
    getAvaUser,
    getRegister,
} from "../../apicalls";
import { Link, useNavigate } from "react-router-dom";

export default function Register() {
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [houseNo, setHouseNo] = useState("");
    const [street, setStreet] = useState("");
    const [township, setTownship] = useState("");
    const [townships, setTownships] = useState([]);
    const [states, setStates] = useState([]);
    const [isTermsAccepted, setIsTermsAccepted] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();

    const nameChange = e => {
        setUsername(e.target.value);
    };
    const emailChange = e => {
        setEmail(e.target.value);
    };
    const phoneChange = e => {
        setPhone(e.target.value);
    };
    const passwordChange = e => {
        setPassword(e.target.value);
    };
    const confirmPasswordChange = e => {
        setConfirmPassword(e.target.value);
    };
    const houseNoChange = e => {
        setHouseNo(e.target.value);
    };
    const streetChange = e => {
        setStreet(e.target.value);
    };
    const townshipChange = e => {
        // console.log(e.target.value)
        setTownship(e.target.value);
    };

    const handleChange = event => {
        setIsTermsAccepted(event.target.checked);
    };
    useEffect(() => {
        (async () => {
            const states = await getAllStates();
            const townships = await getAllTownships();
            setStates(states.data.items);
            setTownships(townships.data.items);
        })();
    }, []);

    console.log(township);

    const registering = async () => {
        if (!isTermsAccepted) {
            setErrorMessage("Please accept the terms and conditions.");
            return;
        }
        if (
            !username ||
            !email ||
            !phone ||
            !password ||
            !confirmPassword ||
            !houseNo ||
            !street ||
            !township
        ) {
            setErrorMessage("Please enter all the required information.");
            return;
        }
        if (password !== confirmPassword) {
            setErrorMessage("Passwords do not match.");
            return;
        }
        try {
            const isUsernameAvailable = await getAvaUser(phone);
            if (isUsernameAvailable.success === false) {
                setErrorMessage(
                    "Username is not available. Please choose a different one."
                );
                return;
            }

            const result = await getRegister(
                username,
                email,
                phone,
                password,
                confirmPassword,
                houseNo,
                street,
                township
            );

            if (result === 409) {
                setErrorMessage("User already exists.");
            } else if (result) {
                navigate("/login");
            } else {
                setErrorMessage("Registration failed. Please try again later.");
            }
        } catch (error) {
            setErrorMessage("An error occurred. Please try again later.");
        }
    };

    return (
        <Box sx={{ background: "red" }}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "end",
                    height: 110,
                }}
            >
                <Avatar alt="Cindy Baker" sx={{ width: 98, height: 98, mb: -5 }} src="/public/images/mtl.png" />
            </Box>
            <Box
                sx={{
                    mx: {
                        xs: 2, // 2 units of margin on extra-small screens (mobile)
                        sm: 4, // 4 units of margin on small screens (tablet)
                        md: 5, // 5 units of margin on medium screens
                        lg: 40, // 40 units of margin on large screens (laptop and above)
                    },
                    mt:2
                }}
                align="center"
            >
                <Card
                    sx={{
                        width: {
                            xs: "100%", // Full width on extra-small screens (mobile)
                            sm: "100%", // 80% width on small screens (tablet)
                            md: "60%", // 60% width on medium screens
                            lg: "433px", // Fixed width of 433px on large screens (laptop and above)
                        },
                        paddingX: {
                            xs: 2, // 2 units of padding on extra-small screens (mobile)
                            sm: 4, // 4 units of padding on small screens (tablet)
                            md: 5, // 5 units of padding on medium screens
                            lg: 5, // 5 units of padding on large screens (laptop and above)
                        },
                        paddingBottom: {
                            xs: 2, // 2 units of padding on extra-small screens (mobile)
                            sm: 4, // 4 units of padding on small screens (tablet)
                            md: 5, // 5 units of padding on medium screens
                            lg: 5, // 5 units of padding on large screens (laptop and above)
                        },
                        paddingTop: {
                            xs: 1, // 1 unit of padding on extra-small screens (mobile)
                            sm: 2, // 2 units of padding on small screens (tablet)
                            md: 3, // 3 units of padding on medium screens
                            lg: 3, // 3 units of padding on large screens (laptop and above)
                        },
                    }}
                    elevation={5}
                >
                    <Box>
                        <TextField
                            sx={{ marginTop: "16px" }}
                            label="Full Name"
                            value={username}
                            onChange={nameChange}
                            fullWidth
                            required
                        />
                        <TextField
                            sx={{ marginTop: "16px" }}
                            label="Email"
                            value={email}
                            onChange={emailChange}
                            fullWidth
                            required
                        />
                        <TextField
                            sx={{ marginTop: "16px" }}
                            label="Phone Number"
                            type="phone"
                            fullWidth
                            value={phone}
                            onChange={phoneChange}
                            required
                        />
                        <TextField
                            sx={{ marginTop: "16px" }}
                            label="Password"
                            value={password}
                            onChange={passwordChange}
                            fullWidth
                            required
                        />
                        <TextField
                            sx={{ marginTop: "16px" }}
                            label="Confirm Password"
                            value={confirmPassword}
                            onChange={confirmPasswordChange}
                            fullWidth
                            required
                        />
                        <TextField
                            sx={{ marginTop: "16px" }}
                            label="Address"
                            value={houseNo}
                            onChange={houseNoChange}
                            fullWidth
                            required
                        />
                        <TextField
                            sx={{ marginTop: "16px" }}
                            label="Street"
                            value={street}
                            onChange={streetChange}
                            fullWidth
                            required
                        />
                        <FormControl
                            fullWidth
                            required
                            sx={{ marginTop: "16px" }}
                        >
                            <InputLabel id="demo-simple-select-label">
                                Township/State
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Township/State"
                                onChange={e => {
                                    townshipChange(e);
                                }}
                            >
                                {townships?.map(township => (
                                    <MenuItem
                                        key={township.key}
                                        value={township.guid}
                                    >
                                        {township.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Typography sx={{ m: 2 }}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isTermsAccepted}
                                            onChange={handleChange}
                                        />
                                    }
                                    label={
                                        <Link
                                            to="/TermsAndConditions"
                                            style={{
                                                textDecoration: "none",
                                                color: "inherit",
                                            }}
                                        >
                                            I agree to all the terms and
                                            conditions.
                                        </Link>
                                    }
                                />
                            </FormGroup>
                        </Typography>

                        {errorMessage && (
                            <Typography sx={{ color: "red", my: 2 }}>
                                {errorMessage}
                            </Typography>
                        )}

                        <Button
                            type="submit"
                            variant="contained"
                            fullWidth
                            sx={{ color: "primary", mt: 2 }}
                            disabled={!isTermsAccepted}
                            onClick={() => {
                                registering();
                            }}
                        >
                            Sign Up
                        </Button>
                    </Box>
                </Card>
            </Box>
            <Box sx={{ background: "red", height: "30px" }}></Box>
        </Box>
    );
}
