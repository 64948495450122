import {
    Avatar,
    Box,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getShopHeader } from "../../apicalls";
import { useLocation, useParams } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";
import { useAuth } from "../../DetailProvider";

export default function ShopsUpper({guid}) {
    const [loading, setLoading] = useState(true);
    const [shopDetail, setShopDetail] = useState({});
    const {translate} = useAuth();
    const urlnav = useLocation();
    const domainnav = window.location.origin;


    console.log(urlnav);

    useEffect(() => {
        (async () => {
            try {
                const result = await getShopHeader(guid);

                // Check if the result.data.items array is empty
                if (result.data.items.length === 0) {
                    setLoading(false);
                    setShopDetail({}); // Set an empty object for shopDetail
                } else {
                    setShopDetail(result.data.items[0]);
                    setLoading(false);
                }

                // console.log(result.data.items[0]);
                // console.log(shopDetail);
            } catch (error) {
                setLoading(false);
                setShopDetail({}); // Set an empty object for shopDetail in case of an error
            }
        })();
    }, []);

    const formatDate = dateString => {
        const date = new Date(dateString);
        return formatDistanceToNow(date, { addSuffix: true });
    };
    return (
        !loading && (
            <Box>
                {Object.keys(shopDetail).length === 0 ? ( // Check if shopDetail is an empty object
                    <Box sx={{ textAlign: "center" , margin:10 }}>
                        <Typography variant="h5" sx={{color:"orangered"}}>---</Typography>
                        {/* You can add more descriptive error messages or actions here */}
                    </Box>
                ) : (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Box>
                            <List>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar
                                            alt={`Avatar n°`}
                                            src={`https://dev2.mtlexpress.com.mm/qq-delivery-backend/v1/image/download/${shopDetail.logo}`}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText primary={shopDetail.name} />
                                </ListItem>
                            </List>
                        </Box>
                        <Box>
                            <List>
                                <ListItem>
                                    <ListItemText
                                        primary={`${shopDetail.stockQuantity} ${translate.ItemSold} ${shopDetail.sellQuantity}`}
                                        sx={{
                                            backgroundColor: "red",
                                            color: "white",
                                            padding: "5px",
                                        }}
                                    />

                                    <ListItemText
                                        primary={formatDate(
                                            shopDetail.createdOn
                                        )} // Format the date here
                                        sx={{
                                            marginLeft: "10px",
                                            backgroundColor: "red",
                                            color: "white",
                                            padding: "5px",
                                        }}
                                    />
                                </ListItem>
                            </List>
                        </Box>
                    </Box>
                )}
            </Box>
        )
    );
}
