import { createContext, useContext, useState } from "react";

const PaymentMethodContext = createContext();

const PaymentMethodProvider = ({ children }) => {
    const [selectedMethod, setSelectedMethod] = useState("");

    return (
        <PaymentMethodContext.Provider value={{selectedMethod, setSelectedMethod}}>
            {children}
        </PaymentMethodContext.Provider>
    );
};

// custom hook
const usePaymentMethod = () => useContext(PaymentMethodContext);

export { usePaymentMethod, PaymentMethodProvider };
