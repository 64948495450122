import { Box, Grid, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import CheckoutSummary from "../Components/Checkout/CheckoutSummary";
import LocalDeliveryInformation from "../Components/Checkout/LocalDeliveryInformation";
import CheckoutShopList from "../Components/Checkout/CheckoutShopList";
import CheckoutAdditionNotes from "../Components/Checkout/CheckoutAdditionNotes";
import ForeignDeliveryInfo from "../Components/Checkout/ForeignDeliveryInfo";
import CheckoutPaymentMethod from "../Components/Checkout/CheckoutPaymentMethod";
import ExchangeRate from "../Components/ExchangeRate";
import { createOrder, getAllStates, getTownshipsWithState } from "../apicalls";
import TermsAndConditions from "../Components/Checkout/TermsAndConditions";
import { usePaymentMethod } from "../Contexts/PaymentMethodContext";

const Checkout = () => {
    // Delivery Information
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [state, setState] = useState("");
    const [states, setStates] = useState([]);
    const [township, setTownship] = useState("");
    const [townships, setTownships] = useState([]);
    const [street, setStreet] = useState("");
    const [note, setNote] = useState("");
    const [fragile, setFragile] = useState(false);
    const [insurance, setInsurance] = useState(false);
    const [gate, setGate] = useState("");

    const nameChange = e => {
        setName(e.target.value);
    };
    const phoneChange = e => {
        setPhone(e.target.value);
    };
    const emailChange = e => {
        setEmail(e.target.value);
    };
    const addressChange = e => {
        setAddress(e.target.value);
    };
    const stateChange = e => {
        setState(e.target.value);
    };
    const townshipChange = e => {
        // console.log(e.target.value)
        setTownship(e.target.value);
    };
    const streetChange = e => {
        setStreet(e.target.value);
    };

    const noteChange = e => {
        setNote(e.target.value);
    };

    const fragileChange = e => {
        setFragile(e.target.checked);
    };

    const insuranceChange = e => {
        setInsurance(e.target.checked);
    };

    const gateChange = e => {
        setGate(e.target.value);
    };

    const finalizeChange = e => {
        setFinalize(e.target.value);
    };

    const [base64String, setBase64String] = useState("");
    const [selectedFile, setSelectedFile] = useState("");

    const handleFileUpload = event => {
        const file = event.target.files[0];
        setSelectedFile(event.target.files[0]);
        const data = new FileReader();
        data.onload = () => {
            setBase64String(data.result);
        };
        data.readAsDataURL(file);
        // const formData = new FormData();
        // formData.append("payment-transfer-image", file);
    };

    useEffect(() => {
        (async () => {
            const states = await getAllStates();
            const townships = await getTownshipsWithState(state);
            setStates(states.data.items);
            setTownships(townships.data.items);
        })();
    }, [state]);

    // Summary
    const storedCart = localStorage.getItem("cart")
        ? JSON.parse(localStorage.getItem("cart"))
        : null;
    const priceArray = storedCart?.map(
        item => item.priceSell * item.rate * item.cartQty
    );
    let cartPrice = priceArray?.reduce((a, current) => {
        return a + current;
    }, 0);

    const [subTotal, setSubTotal] = useState(cartPrice);
    const [deliCost, setDeliCost] = useState(0);
    const [total, setTotal] = useState(subTotal + deliCost);
    // console.log(total);

    // const cartData = JSON.parse(localStorage.getItem("cart"));
    const cartData = localStorage.getItem("cart")
        ? JSON.parse(localStorage.getItem("cart"))
        : null;

    const token = localStorage.getItem("access_token");

    const deliMethods = JSON.parse(localStorage.getItem("deliMethod"));

    const { selectedMethod, setSelectedMethod } = usePaymentMethod();

    const [finalize, setFinalize] = useState("");

    useEffect(() => {
        deliMethods?.map(deli => {
            setDeliCost(deliCost + deli.totalPrice);
            setTotal(subTotal + deli.totalPrice);
            return deli;
        });
    }, []);

    const [isTermsAccepted, setIsTermsAccepted] = useState(false);
    const handleAgree = event => {
        setIsTermsAccepted(event.target.checked);
    };

    // const [prodGuid, setProdGuid] = useState("");
    // const [quantity, setQuantity] = useState("");
    // const [prodName, setProdName] = useState("");
    // const [shopGuid, setShopGuid] = useState("");

    // useEffect(() => {
    //     cartData?.map(data => {
    //         return [
    //             setProdGuid(data.variant_guid),
    //             setQuantity(data.cartQty),
    //             setProdName(data.displayName),
    //             setShopGuid(data.store_guid),
    //         ];
    //     });
    // }, [cartData]);

    const proceedCheckout = async () => {
        const result = await createOrder(
            name,
            phone,
            email,
            address,
            state,
            township,
            street,
            note,
            total,
            cartData,
            token,
            selectedMethod,
            finalize,
            base64String
            // prodGuid,
            // quantity,
            // prodName,
            // shopGuid
        );
        if (result) {
            return result.data;
        } else {
            return false;
        }
    };

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <form>
            <Box sx={{ padding: "10px 50px 20px 50px" }}>
                <Grid container sx={{ pt: 4 }} spacing={1}>
                    <Grid item xs={9}>
                        <Box sx={{ width: "100%" }}>
                            <Tabs value={value} onChange={handleChange}>
                                <Tab label="Local Shipping" />
                                <Tab label="Foreign Shipping" />
                            </Tabs>
                            <TabPanel value={value} index={0}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "24px",
                                    }}
                                >
                                    <LocalDeliveryInformation
                                        name={name}
                                        nameChange={nameChange}
                                        phone={phone}
                                        phoneChange={phoneChange}
                                        email={email}
                                        emailChange={emailChange}
                                        address={address}
                                        addressChange={addressChange}
                                        states={states}
                                        state={state}
                                        stateChange={stateChange}
                                        townships={townships}
                                        township={township}
                                        townshipChange={townshipChange}
                                        street={street}
                                        streetChange={streetChange}
                                        fragile={fragile}
                                        fragileChange={fragileChange}
                                        insurance={insurance}
                                        insuranceChange={insuranceChange}
                                        gate={gate}
                                        gateChange={gateChange}
                                        note={note}
                                        noteChange={noteChange}
                                        base64String={base64String}
                                    />
                                    {/* {console.log(state)} */}
                                    <CheckoutShopList />
                                    <CheckoutPaymentMethod
                                        handleFileUpload={handleFileUpload}
                                        base64String={base64String}
                                        selectedFile={selectedFile}
                                    />
                                    {/* <CheckoutAdditionNotes /> */}
                                </Box>{" "}
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "24px",
                                    }}
                                >
                                    <ForeignDeliveryInfo />
                                    <CheckoutShopList />
                                    <CheckoutPaymentMethod
                                        handleFileUpload={handleFileUpload}
                                    />
                                    <CheckoutAdditionNotes
                                        note={note}
                                        setNote={setNote}
                                        noteChange={noteChange}
                                    />
                                </Box>
                            </TabPanel>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "24.08px",
                            }}
                        >
                            {/* <Services /> */}
                            <ExchangeRate />
                            <TermsAndConditions
                                isTermsAccepted={isTermsAccepted}
                                handleAgree={handleAgree}
                            />
                            <CheckoutSummary
                                subTotal={subTotal}
                                deliCost={deliCost}
                                total={total}
                                proceedCheckout={proceedCheckout}
                                finalizeChange={finalizeChange}
                                isTermsAccepted={isTermsAccepted}
                                name={name}
                                phone={phone}
                                email={email}
                                address={address}
                                township={township}
                                street={street}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </form>
    );
};

const TabPanel = ({ children, value, index }) => {
    return (
        <Box role="tabpanel" hidden={value !== index}>
            {value === index && <Box p={2}>{children}</Box>}
        </Box>
    );
};

export default Checkout;
