import { Box, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getCurrency } from "../apicalls";
import { format, formatRelative, parseISO } from "date-fns";

const ExchangeRate = () => {
    const [useCurrency, setUseCurrency] = React.useState({});
    const [time, setTime] = useState("");
    useEffect(() => {
        (async () => {
            const result = await getCurrency();
            setUseCurrency(result.data.item[0]);
            setTime(result.data.item[0].createdOn);
        })();
    }, []);
    // console.log(typeof(time))
    return (
        <Paper
            elevation={0}
            sx={{
                display: "flex",
                padding: "20px 24px",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "8px",
                bgcolor: "#FFECE6",
            }}
        >
            <Box>
                <Typography color={"secondary"}>{time}</Typography>
                <Typography>Currency Exchange Rate</Typography>
            </Box>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                1 USD = {useCurrency.rate} MMK
            </Typography>
        </Paper>
    );
};

export default ExchangeRate;
