import { Box, Divider } from "@mui/material";
import ShopsUpper from "../Components/SingleShop/ShopsUpper";
import TabsForShop from "../Components/SingleShop/TabsForShop";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuth } from "../DetailProvider";
import { getShopInfo } from "../apicalls";

export default function ViewOneShop() {
    const { sguid } = useParams();
    const [allShops, setAllShops] = useState({});
    const {setSingleShopDetail} = useAuth();

    const domainShop = window.location.href;
    console.log(domainShop);

    useEffect(() => {
        (async () => {
            const result = await getShopInfo(domainShop);
            setAllShops(result.data);
            setSingleShopDetail(result.data);
            console.log(result.data);
        })();
    }, [domainShop]);
    return (
        <Box>
            <ShopsUpper
                guid={allShops.guid}
                sx={{
                    paddingLeft: "72px",
                    paddingTop: "20px",
                    paddingRight: "72px",
                }}
            />
            <Divider />
            <TabsForShop
            guid={allShops.guid}
                sx={{
                    paddingLeft: "72px",
                    paddingTop: "20px",
                    paddingRight: "72px",
                }}
            />
        </Box>
    );
}
