import {
    Box,
    Button,
    Card,
    CardContent,
    InputAdornment,
    OutlinedInput,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { usePaymentMethod } from "../../Contexts/PaymentMethodContext";

const CheckoutSummary = ({
    subTotal,
    total,
    proceedCheckout,
    deliCost,
    finalizeChange,
    isTermsAccepted,
    name,
    phone,
    email,
    address,
    township,
    street,
}) => {
    const navigate = useNavigate();
    const { selectedMethod, setSelectedMethod } = usePaymentMethod();
    const [errorMessage, setErrorMessage] = useState("");
    const [acceptMessage, setAcceptMessage] = useState("");
    const [nameMessage, setNameMessage] = useState("");
    const [phoneMessage, setPhoneMessage] = useState("");
    const [emailMessage, setEmailMessage] = useState("");
    const [addMessage, setAddMessage] = useState("");
    const [townshipMessage, setTownshipMessage] = useState("");
    const [streetMessage, setStreetMessage] = useState("");

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between",
            }}
        >
            <Card
                sx={{
                    width: 400,
                    // height: 300,
                    height: "auto",
                    alignItems: "left",
                    position: "sticky",
                }}
            >
                <CardContent
                    sx={{
                        textAlign: "center",
                        mb: 1,
                    }}
                >
                    <Box>
                        <Typography
                            sx={{
                                display: "flex",
                                fontWeight: "bold",
                            }}
                        >
                            Summary
                        </Typography>
                        <Box sx={{ marginY: "15px" }}>
                            <Typography
                                sx={{
                                    marginBottom: "5px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <span>Subtotal</span>
                                <span>{subTotal.toLocaleString()} Ks</span>
                            </Typography>
                            {/* <Typography
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <span>
                                    <Accordion
                                        sx={{
                                            border: "none",
                                            marginLeft: "-15px",
                                        }}
                                        elevation={0}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>
                                                Delivery Cost
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                gap: "26px",
                                            }}
                                        >
                                            <Typography variant="subtitle1">
                                                Lorem ipsum dolor
                                            </Typography>
                                            <Typography>$$$</Typography>
                                        </AccordionDetails>
                                        <AccordionDetails
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                gap: "26px",
                                            }}
                                        >
                                            <Typography variant="subtitle1">
                                                Lorem ipsum dolor
                                            </Typography>
                                            <Typography>$$$</Typography>
                                        </AccordionDetails>
                                        <AccordionDetails
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                gap: "26px",
                                            }}
                                        >
                                            <Typography variant="subtitle1">
                                                Lorem ipsum dolor
                                            </Typography>
                                            <Typography>$$$</Typography>
                                        </AccordionDetails>
                                        <AccordionDetails
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                gap: "26px",
                                            }}
                                        >
                                            <Typography variant="subtitle1">
                                                Lorem ipsum dolor
                                            </Typography>
                                            <Typography>$$$</Typography>
                                        </AccordionDetails>
                                        <AccordionDetails
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                gap: "26px",
                                            }}
                                        >
                                            <Typography variant="subtitle1">
                                                Lorem ipsum dolor
                                            </Typography>
                                            <Typography>$$$</Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </span>
                                <span>$$$</span>
                            </Typography> */}
                            <Typography
                                sx={{
                                    marginBottom: "5px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <span>Delivery Cost</span>
                                <span>{deliCost.toLocaleString()} Ks</span>
                            </Typography>
                        </Box>
                        <Box>
                            <OutlinedInput
                                type="text"
                                placeholder="Enter Coupon Code"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <Button
                                            variant="outlined"
                                            onClick={() =>
                                                alert(
                                                    "This feature is available for Mobile App."
                                                )
                                            }
                                        >
                                            Apply
                                        </Button>
                                    </InputAdornment>
                                }
                            />
                        </Box>
                        <Box sx={{ marginY: "20px" }}>
                            <Typography
                                sx={{
                                    fontWeight: "bold",
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <span>Total</span>
                                <span>{total.toLocaleString()} Ks</span>
                            </Typography>
                        </Box>
                        {/* <Box
                            sx={{
                                my: 3,
                            }}
                        >
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="pre"
                                    name="radio-buttons-group"
                                    onChange={finalizeChange}
                                >
                                    <FormControlLabel
                                        value="pre"
                                        control={<Radio />}
                                        label="Pre-Finalize (အကုန်ရမှ ယူမယ်)"
                                    />
                                    <FormControlLabel
                                        value="real"
                                        control={<Radio />}
                                        label="Real-Finalize (ရတာပဲ ယူမယ်)"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Box> */}
                        {errorMessage && (
                            <Typography sx={{ color: "red" , display:"block" }}>
                                {errorMessage}
                            </Typography>
                        )}
                        {acceptMessage && (
                            <Typography sx={{ color: "red" }}>
                                {acceptMessage}
                            </Typography>
                        )}
                        {nameMessage && (
                            <Typography sx={{ color: "red" }}>
                                {nameMessage}
                            </Typography>
                        )}
                        {phoneMessage && (
                            <Typography sx={{ color: "red" }}>
                                {phoneMessage}
                            </Typography>
                        )}
                        {emailMessage && (
                            <Typography sx={{ color: "red" }}>
                                {emailMessage}
                            </Typography>
                        )}
                        {addMessage && (
                            <Typography sx={{ color: "red" }}>
                                {addMessage}
                            </Typography>
                        )}
                        {townshipMessage && (
                            <Typography sx={{ color: "red" }}>
                                {townshipMessage}
                            </Typography>
                        )}
                        {streetMessage && (
                            <Typography sx={{ color: "red" }}>
                                {streetMessage}
                            </Typography>
                        )}

                        <Button
                            onClick={async () => {
                                const errorMessages = [];

                                if (!selectedMethod) {
                                    errorMessages.push(
                                        "Please select a payment method."
                                    );
                                }

                                if (!isTermsAccepted) {
                                    errorMessages.push(
                                        "Please accept terms and conditions."
                                    );
                                }

                                if (!name) {
                                    errorMessages.push("Please fill name.");
                                }

                                if (!phone) {
                                    errorMessages.push(
                                        "Please fill phone number."
                                    );
                                }

                                if (!email) {
                                    errorMessages.push("Please fill email.");
                                }

                                if (!address) {
                                    errorMessages.push("Please fill address.");
                                }

                                if (!township) {
                                    errorMessages.push("Please fill township.");
                                }

                                if (!street) {
                                    errorMessages.push("Please fill street.");
                                }

                                if (errorMessages.length > 0) {
                                    // Set the error messages in your state or display them as needed
                                    errorMessages.forEach(message => {
                                        console.error(message); // Log error messages
                                    });

                                    // Optionally, you can set state variables to display the messages in your UI
                                    setErrorMessage(errorMessages.join("\n"));
                                    return; // Prevent further execution
                                }

                                const orderId = await proceedCheckout();
                                navigate(`/checkout-successful/${orderId}`);
                                localStorage.removeItem("cart");
                            }}
                            variant="contained"
                            fullWidth
                        >
                            Place Order
                        </Button>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
};

export default CheckoutSummary;
