import { Box, Card, CardContent, Divider, Typography } from "@mui/material";
import React from "react";
import CheckoutShopCart from "./CheckoutShopCart";
import { useCart } from "../../Contexts/CartContext";

const CheckoutShopList = () => {
    const {cart, setCart} = useCart();
    return (
        <Card sx={{ maxWidth: "full" }} elevation={1}>
            <CardContent>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    Shopping Cart ({cart.length})
                </Typography>
                {/* Dynamic Here */}
                {cart?.map(product => (
                    <Box key={product.guid}>
                        <CheckoutShopCart product={product} />
                        <Divider />
                    </Box>
                ))}
            </CardContent>
        </Card>
    );
};

export default CheckoutShopList;
