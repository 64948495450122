import { Box, Card, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckoutShopCart from "../Components/Checkout/CheckoutShopCart";
import { useCart } from "../Contexts/CartContext";
import SuccessfulSummary from "../Components/Checkout/SuccessfulSummary";
// import { usePaymentMethod } from "../Contexts/PaymentMethodContext";
import { useParams } from "react-router-dom";
import { getOrderDetailForSuccessfulPg } from "../apicalls";

const CheckoutSuccessful = () => {
    const { cart, setCart } = useCart();
    // const { selectedMethod, setSelectedMethod } = usePaymentMethod();
    const { id } = useParams();
    const [details, setDetails] = useState({});
    useEffect(() => {
        (async () => {
            const result = await getOrderDetailForSuccessfulPg(id);
            setDetails(result.data);
        })();
    }, [id]);
    console.log(details);
    return (
        <Box sx={{ padding: "10px 50px 20px 50px" }}>
            {details && (
                <Grid container sx={{ pt: 4 }} spacing={1}>
                    <Grid item xs={9}>
                        <Box>
                            <Card sx={{ marginBottom: "10px" }}>
                                <Box
                                    sx={{
                                        alignItems: "flex-start",
                                        padding: "24px",
                                        gap: "24px",
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        component="h2"
                                        sx={{
                                            fontWeight: "bold",
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "16px",
                                        }}
                                    >
                                        <CheckCircleIcon color="primary" />
                                        Thanks for your purchase!
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-start",
                                            gap: "8px",
                                        }}
                                    >
                                        <Typography>
                                            Your order number is {id}.
                                        </Typography>
                                        <Typography>
                                            Thank you for choosing us! Your
                                            purchase means the world to us, and
                                            we are committed to ensuring your
                                            satisfaction. You will receive an
                                            e-mail confirmation about the
                                            details of your order.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Card>
                            <Card sx={{ marginBottom: "10px" }}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        width: "856px",
                                        padding: "32px",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        gap: "24px",
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        component="h2"
                                        sx={{
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Order Information
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-start",
                                            gap: "16px",
                                        }}
                                    >
                                        <Typography
                                            variant="h6"
                                            color={"secondary"}
                                        >
                                            Shipping Information
                                        </Typography>
                                        <Box>
                                            <Typography>
                                                {details?.contact?.name}
                                            </Typography>
                                            <Typography>
                                                {details?.contact?.primaryPhone}
                                            </Typography>
                                            <Typography>
                                                {details?.contact?.email}
                                            </Typography>
                                            <Typography>
                                                {details?.contact?.township},{" "}
                                                {details?.contact?.stateName}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    {cart?.map(product => (
                                        <CheckoutShopCart product={product} />
                                    ))}
                                    <Divider />
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            gap: "8px",
                                            alignSelf: "stretch",
                                        }}
                                    >
                                        <Typography
                                            variant="h5"
                                            color={"secondary"}
                                        >
                                            Payment Method
                                        </Typography>
                                        <Typography>
                                            {details?.paidWith}
                                        </Typography>
                                    </Box>
                                    <Divider />
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "flex-start",
                                            gap: "16px",
                                            alignSelf: "stretch",
                                        }}
                                    >
                                        <Typography
                                            variant="h6"
                                            color={"secondary"}
                                        >
                                            Additional Notes
                                        </Typography>
                                        <Typography variant="body1">
                                            {details?.remark}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Card>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <SuccessfulSummary details={details} />
                    </Grid>
                </Grid>
            )}
        </Box>
    );
};

export default CheckoutSuccessful;
