import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Tab,
    Tabs,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

const LocalDeliveryMethod = ({ open, handleCloseModal, product }) => {
    const [methodName, setMethodName] = useState("");

    const deliMethods = JSON.parse(localStorage.getItem("deliMethod"));
    useEffect(() => {
        deliMethods?.map(deli => {
            if (deli.guid === product.shopGuid) {
                setMethodName(deli.plan);
            }
            return deli;
        });
    }, [deliMethods]);

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Dialog open={open} onClose={handleCloseModal}>
            <DialogTitle>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    Delivery Methods
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ width: "100%" }}>
                    <Tabs value={value} onChange={handleChange}>
                        <Tab label="Standard Delivery" />
                        <Tab label="Delivery to Highway Gate" />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "24px",
                            }}
                        >
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="delivery"
                                    name="radio-buttons-group"
                                >
                                    <FormControlLabel
                                        value="delivery"
                                        control={<Radio />}
                                        label={
                                            <Box
                                                sx={{
                                                    marginLeft: "16px",
                                                }}
                                            >
                                                <Typography>
                                                    {methodName}
                                                </Typography>
                                                <Typography color="secondary">
                                                    Arrival Time
                                                </Typography>
                                            </Box>
                                        }
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "24px",
                            }}
                        >
                            {/*  */}
                        </Box>
                    </TabPanel>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseModal}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

const TabPanel = ({ children, value, index }) => {
    return (
        <Box role="tabpanel" hidden={value !== index}>
            {value === index && <Box p={2}>{children}</Box>}
        </Box>
    );
};

export default LocalDeliveryMethod;
