import React from "react";
import {
    Box, Card,
    CardContent,
    Divider, Typography
} from "@mui/material";

const SuccessfulSummary = ({ details }) => {
    const totalPrice = details?.totalAmount + details?.deliveryCharges;
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between",
            }}
        >
            {details && (
                <Card
                    sx={{
                        width: 400,
                        // height: 300,
                        height: "auto",
                        alignItems: "left",
                        position: "sticky",
                    }}
                >
                    <CardContent
                        sx={{
                            textAlign: "center",
                            mb: 1,
                        }}
                    >
                        <Box>
                            <Typography
                                sx={{
                                    display: "flex",
                                    fontWeight: "bold",
                                }}
                            >
                                Summary
                            </Typography>
                            <Box sx={{ marginY: "15px" }}>
                                <Typography
                                    sx={{
                                        marginBottom: "5px",
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <span>Subtotal</span>
                                    <span>
                                        {details?.totalAmount?.toLocaleString()}{" "}
                                        Ks
                                        {/* {details?.totalAmount}{" "}
                                        Ks */}
                                    </span>
                                </Typography>
                                <Typography
                                    sx={{
                                        marginBottom: "5px",
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <span>Tax</span>
                                    <span>0 Ks</span>
                                </Typography>
                                <Typography
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <span>Delivery Cost</span>
                                    <span>
                                        {details?.deliveryCharges === null
                                            ? 0
                                            : details?.deliveryCharges?.toLocaleString()}{" "}
                                        Ks
                                        {/* {details?.deliveryCharges === null
                                            ? 0
                                            : details?.deliveryCharges}{" "}
                                        Ks */}
                                    </span>
                                </Typography>
                            </Box>
                            <Divider />
                            <Box sx={{ marginY: "20px" }}>
                                <Typography
                                    sx={{
                                        fontWeight: "bold",
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <span>Total</span>
                                    <span>
                                        {totalPrice?.toLocaleString()} Ks
                                        {/* {totalPrice} Ks */}
                                    </span>
                                </Typography>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};

export default SuccessfulSummary;
