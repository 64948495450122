import { Paper, Typography } from "@mui/material";
import React, { useRef } from "react";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";

const CheckoutFileUpload = ({ handleFileUpload, selectedFile }) => {
    const fileInputRef = useRef();

    return (
        <label htmlFor="file-upload" style={{ cursor: "pointer" }}>
            <input
                id="file-upload"
                type="file"
                accept="image/*"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileUpload}
            />
            {selectedFile && (
                <img
                    width={200}
                    height={300}
                    src={URL.createObjectURL(selectedFile)}
                    alt="Selected"
                />
            )}
            <Paper
                elevation={1}
                sx={{
                    display: "flex",
                    padding: "16px 56px",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    gap: "10px",
                    border: "1px dashed black",
                }}
            >
                <AddPhotoAlternateOutlinedIcon />
                <Typography variant="body1">Upload Image</Typography>
            </Paper>
        </label>
    );
};

export default CheckoutFileUpload;
