import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import { json, useNavigate } from "react-router-dom";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import {
    Autocomplete,
    Badge,
    Divider,
    InputBase,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Menu,
    MenuItem,
    Paper,
    TextField,
    useMediaQuery,
} from "@mui/material";
import { red } from "@mui/material/colors";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import HeaderChangeLanguage from "./HeaderChangeLanguage";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useCart } from "../Contexts/CartContext";
import { useAuth } from "../DetailProvider";
import Translation from "../TranslateEngToMyr/data.json";
import { getSearchShops, getallshops } from "../apicalls";

export default function Header({ toggleDrawer }) {
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = React.useState("");
    const [input, setInput] = React.useState("");

    const [results, setResults] = React.useState([]);

    const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));

    const { translate, searchs, setSearchs , singleShopDetail } = useAuth();
    const { cartLength, setCartLength } = useCart();

    const [shops, setShops] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    const cartData = JSON.parse(localStorage.getItem("cart"));
    const handleSearchInputChange = e => {
        setSearchQuery(e.target.value);
    };

    const [bcounts, setBcounts] = React.useState(0);
    const handleSearchButtonClick = () => {
        // Step 4: Call the search API with the search query
        // You may want to add error handling and redirection logic here
        getSearchShops(searchQuery).then(shops => {
            // Redirect to the search results page with the search query as a parameter
            navigate(`/search?query=${encodeURIComponent(searchQuery)}`);
        });
    };
    const auth = localStorage.getItem("auth");
    const totalQuantity = cartData?.reduce(
        (total, item) => total + item.cartQty,
        0
    );

    React.useEffect(() => {
        // setCartLength(cartData ? cartData.length : 0);
        setCartLength(cartData ? totalQuantity : 0);
    }, [setCartLength, cartLength, cartData, totalQuantity]);

    React.useEffect(() => {
        (async () => {
            const result = await getallshops();
            setShops(result.data.items);
            setLoading(false);
            // console.log(result);
        })();
    }, []);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const fetchData = value => {
        fetch(
            "https://dev2.mtlexpress.com.mm/qq-delivery-backend/v1/pos/shop/listAllShopCategory"
        )
            .then(response => response.json())
            .then(json => {
                const results = json.data.items.filter(user => {
                    return (
                        value &&
                        user &&
                        user.name &&
                        user.name.toLowerCase().includes(value)
                    );
                });
                setResults(results);
                setSearchs(results);

                // Set the open state based on whether there is data in results
                setOpen(results.length > 0);

                console.log(results);
            });
    };

    const handleChange = value => {
        setInput(value);
        fetchData(value);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" sx={{ bgcolor: red[800] }}>
                <Toolbar
                    sx={{ display: "flex", justifyContent: "space-between" }}
                >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                            onClick={toggleDrawer()}
                        >
                            <MenuIcon />
                        </IconButton>
                        <img
                            // src="https://yt3.googleusercontent.com/A9MPXMiA9pRWrn5DP7BewO6loWE6w2MBZRvtqbpMNEGtHNEPKaJhtFdwnn12bFdDMR-hQ_UUVcQ=s900-c-k-c0x00ffffff-no-rj"
                            src="/images/mtl.png"
                            width="50"
                            height="50"
                            alt=""
                        />
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{ pr: 3, ml: 1, cursor: "pointer" }}
                            onClick={() => navigate("/")}
                        >
                            {singleShopDetail?.name}
                        </Typography>
                        {/* <IconButton onClick={()=>{

                        }}>
                            <SlideshowIcon sx={{color:"white",fontSize: "30px"}} />
                        </IconButton> */}
                        {/* <a href="/images/Presentation1 up11111.10.2023.pdf">presentation</a> */}
                        {!isMobile && (
                            <>
                                <Paper
                                    component="form"
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        width: 600,
                                    }}
                                >
                                    <InputBase
                                        sx={{ ml: 1, p: 0, flex: 1 }}
                                        placeholder="Search"
                                        inputProps={{
                                            "aria-label": "Search",
                                        }}
                                        value={input}
                                        onChange={e => {
                                            handleChange(e.target.value);
                                        }}
                                    />

                                    <IconButton
                                        type="button"
                                        aria-label="search"
                                    >
                                        <SearchIcon />
                                    </IconButton>

                                    {/* {results && (
                                        <List>
                                            <ListItem disablePadding>
                                                {results.map(res => {
                                                    return (
                                                        <Box>
                                                            <ListItemButton
                                                                onClick={() => {
                                                                    navigate(
                                                                        `/viewproductcategory/${res.guid}`
                                                                    );
                                                                    handleChange();
                                                                }}
                                                            >
                                                                <ListItemText
                                                                    primary={
                                                                        res.name
                                                                    }
                                                                />
                                                            </ListItemButton>
                                                        </Box>
                                                    );
                                                })}
                                            </ListItem>
                                        </List>
                                    )} */}

                                    {/* <Menu
                                    anchorEl={anchorEl}
                                    open={open} // Use the open state to conditionally control menu visibility
                                    onClose={handleClose}
                                    // transformOrigin={{
                                    //     horizontal: "right",
                                    //     vertical: "top",
                                    // }}
                                    // anchorOrigin={{
                                    //     horizontal: "right",
                                    //     vertical: "top",
                                    // }}
                                >
                                    {results?.map(result => {
                                        return (
                                            <Box>
                                                <MenuItem
                                                    onClick={() => {
                                                        navigate(
                                                            `/viewproductcategory/${result.guid}`
                                                        );
                                                        setAnchorEl(null);
                                                    }}
                                                >
                                                    <ListItemText>
                                                        {result.name}
                                                    </ListItemText>
                                                </MenuItem>
                                            </Box>
                                        );
                                    })}
                                </Menu> */}
                                    <Divider
                                        sx={{ height: 28, m: 0.5 }}
                                        orientation="vertical"
                                    />
                                </Paper>
                                {/* {results && (
                                    <Box sx={{ position: "absolute" }}>
                                        {results.map(res => {
                                            return (
                                                <List sx={{bgcolor:"white"}}>
                                                    <ListItem disablePadding>
                                                        <ListItemButton
                                                            onClick={() => {
                                                                navigate(
                                                                    `/view-one-shop/${res.guid}`
                                                                );
                                                                handleChange();
                                                            }}
                                                        >
                                                            <ListItemText
                                                            sx={{color:"black"}}
                                                                primary={
                                                                    res.name
                                                                }
                                                            />
                                                        </ListItemButton>
                                                    </ListItem>
                                                </List>
                                            );
                                        })}
                                    </Box>
                                )} */}
                            </>
                        )}
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <HeaderChangeLanguage />
                        <IconButton
                            // disabled={!cartData}
                            onClick={() => {
                                navigate("/add-to-cart");
                            }}
                        >
                            {/* <Badge
                                badgeContent={cartData ? cartData.length : 0}
                                color="secondary"
                            >
                                <ShoppingCartIcon sx={{ color: "white" }} />
                            </Badge> */}

                            <Badge
                                badgeContent={cartLength ? cartLength : 0}
                                color="secondary"
                            >
                                <ShoppingCartIcon
                                    sx={{ color: "white", fontSize: "30px" }}
                                />
                            </Badge>
                        </IconButton>
                        <IconButton
                            disabled={!auth}
                            onClick={() => {
                                navigate("/my-account");
                            }}
                        >
                            <AccountCircleIcon
                                sx={{ color: "white", fontSize: "30px" }}
                            />
                        </IconButton>
                        {/* <Breadcrumbs
                            aria-label="breadcrumb"
                            sx={{ color: "white" }}
                        >
                            <Link
                                underline="hover"
                                color="inherit"
                                href="/login"
                            >
                                {translate.Login}
                            </Link>
                            <Link
                                underline="hover"
                                color="inherit"
                                href="/register"
                            >
                                {translate.Register}
                            </Link>
                        </Breadcrumbs> */}
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
