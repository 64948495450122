import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import {
    Box,
    Button,
    CardActions,
    CardMedia,
    Divider,
    Link,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { getOrderDetail } from "../../apicalls";

export default function ViewOrderDetail() {
    const [orderDetail, setOrderDetail] = React.useState({});
    const [orderDeItems, setOrderDeItems] = React.useState({});
    const [orderDeContact, setOrderDeContact] = React.useState({});
    const { guid } = useParams();
    React.useEffect(() => {
        (async () => {
            const result = await getOrderDetail(guid);
            setOrderDetail(result.data);
            setOrderDeItems(result.data.items[0]);
            setOrderDeContact(result.data.contact);
            // console.log(result.data.items);
        })();
    }, [guid]);

    function formatDate(dateString) {
        const options = { year: "numeric", month: "short", day: "numeric" };
        const date = new Date(dateString);
        return date.toLocaleDateString("en-US", options);
    }

    return (
        <>
            <Box sx={{ display: "flex" }}>
                <Box>
                    <Card sx={{ maxWidth: 600, margin: 2 }}>
                        <Box sx={{ margin: 2 }}>
                            <Box sx={{ display: "flex" }}>
                                <Typography variant="h5">
                                    Order #{orderDetail?.salesId}
                                </Typography>
                                <Typography
                                    sx={{
                                        borderRadius: "10px",
                                        padding: "3px",
                                        marginLeft: "5px",
                                        backgroundColor: "#E5FFDC",
                                        color: "green",
                                    }}
                                >
                                    {orderDetail?.status}
                                </Typography>
                            </Box>
                            <Box sx={{ display: "flex" }}>
                                <Typography sx={{ color: "#838383" }}>
                                    {formatDate(orderDetail?.createdOn)}
                                </Typography>
                                <Typography
                                    sx={{
                                        color: "#838383",
                                        marginLeft: 2,
                                        marginRight: 2,
                                        marginBottom: 5,
                                    }}
                                >
                                    |
                                </Typography>
                                <Typography sx={{ color: "#838383" }}>
                                    QTY : {orderDeItems?.quantity}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    sx={{ color: "#838383", marginBottom: 2 }}
                                    variant="h5"
                                >
                                    Shipping Information
                                </Typography>
                                <Typography
                                    sx={{ marginBottom: 1 }}
                                    variant="h5"
                                >
                                    {orderDeContact?.name}
                                </Typography>
                                <Typography
                                    sx={{ marginBottom: 1 }}
                                    variant="h5"
                                >
                                    {orderDeContact?.primaryPhone}
                                </Typography>
                                <Typography
                                    sx={{ marginBottom: 1 }}
                                    variant="h5"
                                >
                                    {orderDeContact?.email}
                                </Typography>
                                <Typography
                                    sx={{ marginBottom: 3 }}
                                    variant="h5"
                                >
                                    {orderDeContact?.street},
                                    {orderDeContact?.township},
                                    {orderDeContact?.stateName}
                                </Typography>
                            </Box>
                            <Divider sx={{ marginBottom: 3 }} />
                            <Box sx={{ display: "flex" }}>
                                <Avatar
                                    src={`https://dev2.mtlexpress.com.mm/qq-delivery-backend/v1/image/download/${orderDetail?.shopLogo}`}
                                />
                                <Typography variant="h6" sx={{ marginLeft: 1 }}>
                                    {orderDetail?.shopName}
                                </Typography>
                            </Box>
                            <Box sx={{ display: "flex", marginTop: 1 }}>
                                <Typography>
                                    Delivery : {orderDetail?.deliveryCharges}{" "}
                                    MMK |{" "}
                                </Typography>
                                <Typography>
                                    Couriered By : {orderDetail?.courierName}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginTop: 1,
                                }}
                            >
                                <Box>
                                    <img
                                        src={`https://dev2.mtlexpress.com.mm/qq-delivery-backend/v1/image/download/${orderDetail?.productLogo}`}
                                        width="70"
                                        height="70"
                                        alt=""
                                    />
                                </Box>
                                <Box>
                                    {orderDeItems?.productName},
                                    {orderDeItems?.productType}
                                </Box>
                                <Box>
                                    <Typography>
                                        {orderDeItems?.price} MMK
                                    </Typography>
                                    <Typography>
                                        QTY : {orderDeItems?.quantity}
                                    </Typography>
                                </Box>
                            </Box>
                            <Divider sx={{ marginBottom: 3, marginTop: 3 }} />
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography
                                    sx={{ color: "#838383", marginBottom: 2 }}
                                    variant="h5"
                                >
                                    PaymentMethod :{" "}
                                </Typography>
                                <Typography variant="h6">
                                    {orderDetail?.paidWith}
                                </Typography>
                            </Box>
                            <Divider sx={{ marginBottom: 3 }} />
                            <Box>
                                <Typography>Additional Note</Typography>
                                <CardContent>
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                    >
                                        {orderDetail?.remark}
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Box>
                    </Card>
                </Box>
                <Box>
                    <Card sx={{ maxWidth: 600, margin: 2 , height:"260px" }}>
                        <CardContent>
                            <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                            >
                                Summary
                            </Typography>
                            <Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Typography>Product Total Cost</Typography>
                                    <Typography sx={{ marginLeft: 4 }}>
                                        {orderDetail?.totalAmount} : MMK
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Typography>Delivery Cost</Typography>
                                    <Typography sx={{ marginLeft: 4 }}>
                                        {orderDetail?.deliveryCharges} : MMK
                                    </Typography>
                                </Box>
                                {/* <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography>Delivery Cost</Typography>
                                <Typography sx={{ marginLeft: 4 }}>
                                    500 : MMK
                                </Typography>
                            </Box> */}
                            </Box>
                            <Divider sx={{ margin: 3 }} />
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography variant="h5">Total</Typography>
                                <Typography variant="h5" sx={{ marginLeft: 4 }}>
                                    {orderDetail?.totalAmount +
                                        orderDetail?.deliveryCharges}{" "}
                                    : MMK
                                </Typography>
                            </Box>
                        </CardContent>
                        <Link
                            href={`https://dev2.mtlexpress.com.mm/qq-delivery-backend/v1/pos/download/sales/invoice/${guid}`}
                            underline="none"
                            sx={{
                                padding:1,
                                backgroundColor:"wheat",
                                borderRadius:"10px",
                                margin: 3,
                                alignItems:"center"
                            }}
                        >
                            Download Invoice
                        </Link>
                    </Card>
                </Box>
            </Box>
            <Box></Box>
        </>
    );
}
