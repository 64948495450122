import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { grey, red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
    Box,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
} from "@mui/material";
import { getUserInfo } from "../../apicalls";

const ExpandMore = styled(props => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
    }),
}));



export default function ViewMyAccount() {
    const [expanded, setExpanded] = React.useState(false);
    const token = localStorage.getItem("access_token");
    const [userInfo,setUserInfo] = React.useState({});



    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    React.useEffect(()=>{
        (async () => {
            const result = await getUserInfo(token);
            // console.log(result.data);
            setUserInfo(result.data);
            localStorage.setItem("guid", result.data.guid)
        })();
    },[token]);

    return (
        <Card sx={{ width: "100%" }}>
            <CardHeader
                avatar={
                    <Avatar alt="Remy Sharp" sx={{width:98,height:98}} src={`https://dev2.mtlexpress.com.mm/qq-delivery-backend/v1/image/download/${userInfo.avatar}`} />
                }
                title={<Typography variant="h4">{userInfo.name}</Typography>}
            />
            <Box sx={{marginLeft:3,marginBottom:1,marginRight:3}}>
              <Typography variant="subtitle1" sx={{color:"grey"}}>Full Name</Typography>
              <Typography variant="h6">{userInfo.name}</Typography>
              <Divider sx={{marginTop:"5px",marginBottom:"5px"}}/>
              <Typography variant="subtitle1" sx={{color:"grey"}}> Phone Number </Typography>
              <Typography variant="h6">{userInfo.phone}</Typography>
              <Divider sx={{marginTop:"5px",marginBottom:"5px"}}/>
              <Typography variant="subtitle1" sx={{color:"grey"}}> Email </Typography>
              <Typography variant="h6">{userInfo.email}</Typography>
              <Divider sx={{marginTop:"5px",marginBottom:"5px"}}/>
              <Typography variant="subtitle1" sx={{color:"grey"}}> Address </Typography>
              <Typography variant="h6">{userInfo.address}</Typography>
              <Divider sx={{marginTop:"5px",marginBottom:"5px"}}/>
            </Box>
        </Card>
    );
}
