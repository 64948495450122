import React, { useState } from "react";
import {
    Box,
    Button,
    Checkbox,
    Divider,
    IconButton,
    Typography,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ShareIcon from "@mui/icons-material/Share";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import AddOn from "./AddOn";
import { useEffect } from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { useCart } from "../../Contexts/CartContext";
import { toast } from "react-hot-toast";
import { useAuth } from "../../DetailProvider";

export default function ProductInfo({
    variantDetailItems,
    variantDetail,
    proInfo,
    pguid,
}) {
    const { translate, setTranslate, whistLists, setWhistLists } = useAuth();
    const [quantity, setQuantity] = useState(1);
    const navigate = useNavigate();
    const { loadingContext, setLoadingContext, selectImgae, setSelectImgae } = useAuth();
    const [showMsg, setShowMsg] = useState(false);
    const token = localStorage.getItem("access_token");
    const [selectedButton, setSelectedButton] = useState("");
    const [showDetail, setShowDetail] = useState({});
    const [productToCart, setProductToCart] = useState({});
    const { cart, setCart } = useCart();
    const [isLinkCopied, setIsLinkCopied] = useState(false);
    const [showMsgWl,setShowMsgWl] = useState(false)

    const handleDecrement = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };

    const handleIncrement = () => {
        setQuantity(quantity + 1);
    };

    const copyToClipboard = () => {
        const currentURL = window.location.href;

        navigator.clipboard.writeText(currentURL).then(() => {
            setIsLinkCopied(true);

            setTimeout(() => {
                setIsLinkCopied(false);
            }, 2000);

            toast.success("Link Copied");
        });
    };

    const handleButtonClick = guid => {
        if (!guid) {
            setSelectedButton(variantDetailItems[0].guid);
            setShowDetail(variantDetailItems[0]);
        } else {
            const selectedDetail = variantDetailItems.find(
                item => item.guid === guid
            );
            setSelectedButton(guid);
            setShowDetail(selectedDetail);
            setSelectImgae(guid);
        }
    };

    useEffect(() => {
        if (selectedButton) {
            const selectedDetail = variantDetailItems.find(
                item => item.guid === selectedButton
            );
            setProductToCart(selectedDetail);
        }
    }, [selectedButton, variantDetailItems]);

    const handleAddToWhistLists = () => {
        if (variantDetail) {
            // Check if the variantDetail is not null or undefined
            // Create a new array with the current variantDetail and previous whistLists
            const updatedWhistLists = [...whistLists, variantDetail];

            // Update the whistLists state with the new array
            setWhistLists(updatedWhistLists);
            localStorage.setItem("wishlist", JSON.stringify(updatedWhistLists));
            console.log(whistLists);

            setShowMsgWl(true);

            setTimeout(() => {
                setShowMsgWl(false);
            }, 2000);

            // Optionally, you can save the updatedWhistLists to local storage or perform any other necessary actions
            // Example: localStorage.setItem("whistLists", JSON.stringify(updatedWhistLists));
        }
    };

    return (
        <Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box>
                    {variantDetail?.stockQty !== null ? (
                        <Button
                            variant="contained"
                            startIcon={<CheckCircleOutlineIcon />}
                            sx={{
                                color: "green[800]",
                                bgcolor: "green[100]",
                                borderRadius: "3px",
                                boxShadow: "none",
                                pointerEvents: "none",
                            }}
                        >
                            In Stock.
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            startIcon={<CheckCircleOutlineIcon />}
                            sx={{
                                color: "green[800]",
                                bgcolor: "green[100]",
                                borderRadius: "3px",
                                boxShadow: "none",
                                pointerEvents: "none",
                            }}
                        >
                            Preorder (limited items)
                        </Button>
                    )}
                </Box>
                <Box>
                    <IconButton color="secondary" aria-label="add an alarm">
                        <ShareIcon onClick={copyToClipboard} />
                    </IconButton>
                    <IconButton
                        color="primary"
                        aria-label="add to shopping cart"
                        onClick={handleAddToWhistLists} // Add this onClick handler
                    >
                        <FavoriteBorderIcon />
                    </IconButton>
                </Box>
            </Box>
            <Box sx={{ p: 0.5 }}>
                <Typography variant="h5">{variantDetail?.name}</Typography>
            </Box>
            <Box sx={{ p: 0.5 }}>
                <Typography>
                    Brand : <span>{variantDetailItems[0].shopName}</span>
                </Typography>
            </Box>
            <Box sx={{ p: 0.5, display: "flex", gap: 1 }}>
                <Typography variant="h5">Ks : </Typography>
                {/* <Typography variant="h6">
                    {(showDetail?.priceSell || 0) * showDetail?.rate}
                </Typography> */}
                <Typography variant="h6">
                    {showDetail?.priceSell
                        ? showDetail.priceSell * showDetail.rate
                        : variantDetailItems[0].priceSell *
                          variantDetailItems[0].rate}
                </Typography>
            </Box>
            <Box sx={{ p: 0.5 }}>
                <Typography sx={{ pb: 1 }}>Option:</Typography>
                {variantDetailItems.map(variantDetailItem => {
                    return (
                        <Box
                            sx={{
                                paddingBottom: "10px",
                                display: "inline",
                            }}
                            key={variantDetailItem.guid}
                        >
                            <Button
                                variant="outlined"
                                onClick={() =>
                                    handleButtonClick(variantDetailItem.guid)
                                }
                                sx={{
                                    margin: "4px",
                                    backgroundColor:
                                        selectedButton ===
                                        variantDetailItem.guid
                                            ? "rgba(255, 0, 0, 0.2)"
                                            : "transparent",
                                }}
                            >
                                {variantDetailItem.name}
                            </Button>
                        </Box>
                    );
                })}
            </Box>
            <Divider light />
            <Box sx={{ paddingTop: "10px", paddingBottom: "10px" }}>
                <Typography>+ Add On</Typography>
                <AddOn pguid={pguid} />
            </Box>
            <Divider light />
            <Box>
                <Box sx={{ paddingY: "10px" }}>
                    <Box sx={{ display: "inline-block", pr: 3 }}>
                        <Typography>QTY : </Typography>
                    </Box>
                    <Box sx={{ display: "inline-block" }}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: "0 90px 10px 0",
                                gap: "16px",
                            }}
                        >
                            <Box>
                                <Button
                                    variant="outlined"
                                    onClick={handleDecrement}
                                    disabled={quantity <= 1}
                                >
                                    <RemoveIcon />
                                </Button>
                            </Box>
                            <Box>
                                <Typography variant="body2">
                                    {quantity}
                                </Typography>
                            </Box>
                            <Box>
                                <Button
                                    variant="outlined"
                                    onClick={handleIncrement}
                                    disabled={
                                        quantity >= productToCart?.stockQty
                                    }
                                >
                                    <AddIcon />
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                    }}
                >
                    {!productToCart.priceSell ? (
                        <>
                            <Button
                                variant="outlined"
                                sx={{ width: "47%" }}
                                color="error"
                                disabled
                            >
                                {translate.BuyNow}
                            </Button>
                            <Button
                                sx={{ width: "47%" }}
                                variant="contained"
                                color="success"
                                disabled
                                onClick={() => {
                                    if (token) {
                                        const cartData =
                                            JSON.parse(
                                                localStorage.getItem("cart")
                                            ) || [];
                                        const existingItemIndex =
                                            cartData.findIndex(
                                                item =>
                                                    item.guid ===
                                                    productToCart.guid
                                            );
                                        if (existingItemIndex !== -1) {
                                            cartData[
                                                existingItemIndex
                                            ].cartQty += 1;
                                        } else {
                                            productToCart.cartQty = quantity;
                                            cartData.push({
                                                productName: variantDetail.name,
                                                ...productToCart,
                                            });
                                        }
                                        setCart(cartData);
                                        toast.success("Item added to Cart!");
                                        localStorage.setItem(
                                            "cart",
                                            JSON.stringify(cartData)
                                        );
                                        setShowMsg(true);
                                        setTimeout(() => {
                                            setShowMsg(false);
                                        }, 3000);
                                    } else {
                                        toast.error("Please Login First!");
                                        navigate("/login");
                                    }
                                    // Show the message for 2 seconds
                                    setShowMsg(true);
                                    setTimeout(() => {
                                        setShowMsg(false);
                                    }, 2000);
                                }}
                            >
                                {translate.AddToCard}
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button
                                variant="outlined"
                                sx={{ width: "47%" }}
                                color="error"
                                onClick={() => {
                                    if (token) {
                                        const cartData =
                                            JSON.parse(
                                                localStorage.getItem("cart")
                                            ) || [];
                                        const existingItemIndex =
                                            cartData.findIndex(
                                                item =>
                                                    item.guid ===
                                                    productToCart.guid
                                            );
                                        if (existingItemIndex !== -1) {
                                            cartData[
                                                existingItemIndex
                                            ].cartQty += 1;
                                        } else {
                                            productToCart.cartQty = quantity;
                                            cartData.push({
                                                productName: variantDetail.name,
                                                ...productToCart,
                                            });
                                        }
                                        setCart(cartData);
                                        toast.success("Item added to Cart!");
                                        localStorage.setItem(
                                            "cart",
                                            JSON.stringify(cartData)
                                        );
                                        navigate("/checkout");

                                    } else {
                                        toast.error("Please Login First!");
                                        navigate("/login");
                                    }
                                }}
                            >
                                {translate.BuyNow}
                            </Button>
                            <Button
                                sx={{ width: "47%" }}
                                variant="contained"
                                color="success"
                                onClick={() => {
                                    if (token) {
                                        const cartData =
                                            JSON.parse(
                                                localStorage.getItem("cart")
                                            ) || [];
                                        const existingItemIndex =
                                            cartData.findIndex(
                                                item =>
                                                    item.guid ===
                                                    productToCart.guid
                                            );
                                        if (existingItemIndex !== -1) {
                                            cartData[
                                                existingItemIndex
                                            ].cartQty += 1;
                                        } else {
                                            productToCart.cartQty = quantity;
                                            cartData.push({
                                                productName: variantDetail.name,
                                                ...productToCart,
                                            });
                                        }
                                        setCart(cartData);
                                        toast.success("Item added to Cart!");
                                        localStorage.setItem(
                                            "cart",
                                            JSON.stringify(cartData)
                                        );
                                        setShowMsg(true);
                                        setTimeout(() => {
                                            setShowMsg(false);
                                        }, 3000);
                                    } else {
                                        toast.error("Please Login First!");
                                        navigate("/login");
                                    }
                                }}
                            >
                                {translate.AddToCard}
                            </Button>
                        </>
                    )}
                </Box>
                {isLinkCopied && (
                    <Typography
                        color="success"
                        sx={{
                            marginTop: "10px",
                            backgroundColor: "rgba(76, 175, 80, 0.8)",
                            color: "#fff",
                            padding: "5px 10px",
                            borderRadius: "5px",
                            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                            display: "inline-block",
                        }}
                    >
                        Copied Link
                    </Typography>
                )}
                {showMsg && (
                    <Typography
                        color="success"
                        sx={{
                            marginTop: "10px",
                            backgroundColor: "rgba(76, 175, 80, 0.8)",
                            color: "#fff",
                            padding: "5px 10px",
                            borderRadius: "5px",
                            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                            display: "inline-block",
                        }}
                    >
                        This product is added to cart.
                    </Typography>
                )}
                {showMsgWl && (
                    <Typography
                        color="success"
                        sx={{
                            marginTop: "10px",
                            backgroundColor: "rgba(76, 175, 80, 0.8)",
                            color: "#fff",
                            padding: "5px 10px",
                            borderRadius: "5px",
                            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                            display: "inline-block",
                        }}
                    >
                        This product is added to wishlist.
                    </Typography>
                )}
            </Box>
        </Box>
    );
}
