import React from "react";
import {
    Box,
    Card,
    CardContent,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    Select,
    TextField,
    Typography,
} from "@mui/material";

const ForeignDeliveryInfo = () => {
    return (
        <Card sx={{ maxWidth: "full" }} elevation={1}>
            <CardContent>
                <form>
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                        Delivery Information
                    </Typography>
                    <Box
                        sx={{
                            gap: "24px",
                        }}
                    >
                        <TextField
                            sx={{
                                marginTop: "16px",
                            }}
                            label="Full Name"
                            // value={name}
                            // onChange={handleNameChange}
                            fullWidth
                            required
                        />
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                gap: "24px",
                                marginY: "16px",
                            }}
                            fullWidth
                        >
                            <TextField
                                label="Phone Number"
                                type="phone"
                                sx={{
                                    width: "50%",
                                }}
                                // value={email}
                                // onChange={handleEmailChange}
                                required
                            />
                            <TextField
                                label="Email"
                                type="email"
                                sx={{
                                    width: "50%",
                                }}
                                // value={message}
                                // onChange={handleMessageChange}
                                required
                            />
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                gap: "24px",
                                marginY: "16px",
                            }}
                            fullWidth
                        >
                            <FormControl
                                sx={{
                                    width: "50%",
                                }}
                            >
                                <InputLabel id="demo-simple-select-label">
                                    Country
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Township"
                                    // value={age}
                                    // onChange={handleChange}
                                ></Select>
                            </FormControl>
                            <TextField
                                label="Postcode / ZIP"
                                sx={{
                                    width: "50%",
                                }}
                                // value={message}
                                // onChange={handleMessageChange}
                                required
                            />
                        </Box>
                        <TextField
                            label="Address"
                            fullWidth
                            // value={message}
                            // onChange={handleMessageChange}
                            required
                        />
                    </Box>
                </form>
            </CardContent>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Card
                    sx={{
                        width: "100%",
                        height: "auto",
                        alignItems: "left",
                        position: "sticky",
                    }}
                >
                    <CardContent
                        sx={{
                            textAlign: "center",
                            mb: 1,
                        }}
                    >
                        <Box>
                            <Typography
                                sx={{
                                    display: "flex",
                                    fontWeight: "bold",
                                }}
                            >
                                Services
                            </Typography>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label="Warranty Service Request"
                                />
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label="Product maintenance (Fragile)"
                                />
                            </FormGroup>
                            <Typography
                                variant="body1"
                                component="p"
                                sx={{
                                    fontSize: "16px",
                                    lineHeight: "140%",
                                }}
                                align="start"
                            >
                                Lorem ipsum dolor sit amet consectetur. In amet
                                ac turpis non. Ut molestie sit{" "}
                            </Typography>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </Card>
    );
};

export default ForeignDeliveryInfo;
