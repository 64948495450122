import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Divider,
    Grid,
    Typography,
} from "@mui/material";
import {  getAllProducts } from "../../apicalls";

export default function RelatedProduct({categ,pguid}) {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            const result = await getAllProducts(categ);
            setProducts(result.data.items);
            setLoading(false);
            console.log(result.data.items);
            // console.log(result);
        })();
    }, [categ]);

    const filteredProducts = products.filter(shop => shop.guid !== pguid);

    return (
        <Box>
           
            <Grid container spacing={2}>
                {filteredProducts.map((shop) => (
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        key={shop.guid}
                    >
                        <Card
                            sx={{
                                display: "flex",
                                marginRight:"100px",
                                flexDirection: "column",
                                height: "100%",
                                width: "100%",
                                minWidth: 250
                            }}
                            onClick={() => {
                                navigate(
                                    `/oneproductdetail/${categ}/${shop.guid}`
                                );
                            }}
                        >
                            <CardActionArea>
                                <CardMedia
                                    component="img"
                                    height="200"
                                    image={`https://dev2.mtlexpress.com.mm/qq-delivery-backend/v1/image/download/${shop.photo}`}
                                    alt={shop.displayName}
                                />
                                <CardContent sx={{ flexGrow: 1 }}>
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                    >
                                        {shop.displayName}
                                    </Typography>
                                    <Divider />
                                    <Typography
                                        variant="subtitle1"
                                        color="textSecondary"
                                    >
                                        KS: {shop.priceSell}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}
