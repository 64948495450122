import { Box } from "@mui/material";
import TabsForAccount from "../Components/Account/TabsForAccount";
import ErrorPage from "../Components/ErrorPage";

export default function MyAccount() {
  const auth = localStorage.getItem("auth");
  console.log(auth);

  return (
    <Box>
      {auth === "true" ? (
        <TabsForAccount />
      ) : (
        <ErrorPage />
      )}
    </Box>
  );
}
