import { useState, createContext, useContext } from "react";

const DetailContext = createContext();

export function useAuth() {
    return useContext(DetailContext);
}

export default function DetailProvider({ children }) {
    const [detailInfo, setDetailInfo] = useState(null);
    const [translate, setTranslate] = useState({});
    const [loadingContext, setLoadingContext] = useState(true);
    const [auth, setAuth] = useState(false);
    const [whistLists, setWhistLists] = useState([]);
    const [searchs, setSearchs] = useState([]);
    const [authUser, setAuthUser] = useState({});
    const [selectImgae, setSelectImgae] = useState("");
    const [singleShopDetail, setSingleShopDetail] = useState({});
    return (
        <DetailContext.Provider
            value={{
                detailInfo,
                setDetailInfo,
                loadingContext,
                setLoadingContext,
                translate,
                setTranslate,
                auth,
                setAuth,
                authUser,
                setAuthUser,
                whistLists,
                setWhistLists,
                searchs,
                setSearchs,
                selectImgae,
                setSelectImgae,
                singleShopDetail,
                setSingleShopDetail,
            }}
        >
            {children}
        </DetailContext.Provider>
    );
}
